<template>
  <div><el-card class="card" :body-style="{ padding: '5px' }">
      <van-cell-group inset style="margin:0 0 5px 0">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="测点名称" name="1">
            {{  wxcW_WXCWSensor.sensorName }}</van-collapse-item>
        </van-collapse>
        <van-collapse v-model="activeNames">
          <van-collapse-item title="时间间隔" name="2">
            {{ MeiSiXiaoShiBaoJing.prev_4Hour }}</van-collapse-item>
        </van-collapse>
        <van-cell title="平均温度(℃)" title-class="font" value-class="fontsize">{{ MeiSiXiaoShiBaoJing.avg_Temperature_Prev }}</van-cell>
        <van-collapse v-model="activeNames">
          <van-collapse-item title="时间间隔" name="3">
            {{ MeiSiXiaoShiBaoJing.next_4Hour }}</van-collapse-item>
        </van-collapse>
        <van-cell title="平均温度(℃)" title-class="font" value-class="fontsize">{{ MeiSiXiaoShiBaoJing.avg_Temperature_Next }}</van-cell>
        <van-cell title="每四小时降温(℃)" title-class="font" value-class="fontsize">{{ MeiSiXiaoShiBaoJing.meiSiXiaoShiJiangWen }}</van-cell>
      </van-cell-group>
      <el-row style="margin-top: 5px;">
        <van-cell title="报警时间">{{ MeiSiXiaoShiBaoJing.createTime }}</van-cell>
      </el-row>
    </el-card></div>
</template>


<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/css/digital.css";
Vue.use(Vant);
export default {
  data() {
    return {
      MeiSiXiaoShiBaoJing: {},
      wxcW_WXCWSensor: {},
      activeNames: ["1", "2", "3"],
    };
  },
  methods: {
    getMeiSiXiaoShiBaoJing() {
      let that = this;
      that.axios
        .post("WXCW81/GetMeiSiXiaoShiBaoJingById", {
          onlyInt: that.$route.query.id,
        })
        .then((response) => {
          that.MeiSiXiaoShiBaoJing = response.data.data;
          that.wxcW_WXCWSensor = response.data.data.wxcW_WXCWSensor;
        });
    },
  },
  mounted() {
    document.title = "每四小时报警";
    let that = this;
    that.getMeiSiXiaoShiBaoJing();
  },
};
</script>

<style scoped>
.card {
  background-color: #409eff;
}
.font {
  font-size: 1em;
}
.fontsize {
  font-size: 1.2em;
  color: red;
}
</style>